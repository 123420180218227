.uploadTable {
  table {
    max-width: 100vw;
    min-height: 380px;
    overflow: scroll;
  }
  td {
    max-height: 80px;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: scroll;
    border-bottom: 1px dotted;
  }
  th {
    height: 60px;
  }
}

@media (max-width: 991px) {
  .chakra-button__icon {
    margin-inline-end: 0 !important;
  }
}

input[disabled] {
  -webkit-text-fill-color: var(--chakra-colors-docentLabel);
}

.guide-content > ol,
.guide-content > ul {
  margin-left: 35px;
}

.guide-content > ol > li,
.guide-content > ul > li {
  margin-top: 10px;
}
